import { Link } from 'react-router-dom';
import styles from './NavBar.module.scss';

export default function NavBar() {
    return (
        <header className={styles.header}>
            <div>
                <button className={styles.btn}>
                    <Link to="/">
                        <div className={styles.iconContainer}>
                            <img src='assets/icons/home.svg' className={styles.img} alt="Home" />
                        </div>
                    </Link>
                </button>
                <h3>home</h3>
            </div>

            <div>
                <button className={styles.btn}>
                    <Link to="/HomeLista">
                        <div className={styles.iconContainer}>
                            <img src='assets/icons/list.svg' className={styles.img} alt="Lista de presença" />
                        </div>
                    </Link>
                </button>
                <h3>gerir de dados</h3>
            </div>

            <div>
                <button className={styles.btn}>
                    <Link to="/HomeMVS">
                        <div className={styles.iconContainer}>
                            <img src='assets/icons/mvs.svg' className={styles.img} alt="MVS" />
                        </div>
                    </Link>
                </button>
                <h3>MVS</h3>
            </div>

            {/* Links comentados, se necessário
            <Link className={styles.link} to="/Lista">Lista de presença</Link>
            <Link className={styles.link} to="/HomeMVS">MVS</Link>
            */}
        </header>
    );
}


