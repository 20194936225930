import axios from 'axios';

const http = axios.create({
    baseURL: 'http://localhost:9004/'
});

const http2 = axios.create({
    baseURL: 'https://api.plataformainatu.com.br:9005/'
});

export default http2;