import Banner from "../../components/Banner"
import Footer from "../../components/Footer"
import NavBar from "../../components/NavBar"

export default function Home() {

  return (
    <>
      <Banner />

      <NavBar />

      <Footer />
    </>
  )
}
