import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import HomeLista from "../pages/HomeLista/HomeLista";
import HomeMVS from '../pages/HomeMVS/HomeMVS';

import Acoes from '../pages/Acoes/Acoes';
import Lista from '../pages/Lista/Lista';
import ResultadoLista from '../pages/ResultadoLista/ResultadoLista';
import MVS from '../pages/MVS/MVS';
import Graficos from '../pages/Graficos/Graficos';

export default function Rotas() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="HomeLista" element={<HomeLista/>} />
                <Route path="HomeMVS" element={<HomeMVS/>} />
                <Route path="Acoes" element={<Acoes/>} />
                <Route path="Lista" element={<Lista/>} />
                <Route path="ResultadoLista" element={<ResultadoLista/>} />
                <Route path="MVS" element={<MVS/>} />
                <Route path="Graficos" element={<Graficos/>} />
            </Routes>
        </Router>
    );
}

