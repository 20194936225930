// Link.jsx
import { Link as RouterLink } from 'react-router-dom';
import styles from './Link.module.scss';

const Link = ({ text, href, ...props }) => {
    return (
        <RouterLink to={href} className={styles.link} {...props}>
            {text}
        </RouterLink>
    );
};

export default Link;
